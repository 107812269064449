<template>
    <div v-if="show" :style="descWrapStyle" :class="descClass">
        <div v-if="showTitleNode" class="list_photo_item_title" :style="titleStyle" v-html="titleText"></div>
        <div v-if="showDescNode" class="list_photo_item_desc" :style="titleDesc" v-html="descText"></div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import fontMixin from './fontMixin.js';
import { encodeHtmlStr } from '../../utils';
import listPhotosMixin from './listPhotosMixin';

export default {
    props: {
        module: {
            type: Object,
            default: () => ({}),
        },
        photoItem: {
            type: Object,
            default: () => ({}),
        },
    },
    mixins: [fontMixin, listPhotosMixin],
    data() {
        return {};
    },
    computed: {
        ...mapState(['manageMode']),
        ...mapGetters(['isMobi']),
        show() {
            let isShow = true;
            if (!this.isMobi) {
                // 描述样式0并且动画有遮罩，交由图片特效组件处理
                if (this.descStyle === 0 && this.module.commProp.pa.mt !== 0) {
                    isShow = false;
                }
                if (!this.isMobi && this.isAllMaskStyle3) {
                    isShow = true;
                }
            } else {
                // 手机视图下
                if (this.isHalfMaskStyle1 || this.isAllMaskStyle1) {
                    isShow = false;
                }
            }
            return isShow && (this.showTitleNode || this.showDescNode);
        },
        showTitleNode() {
            return this.showTitle && this.titleText;
        },
        showDescNode() {
            let isShow = true;
            if (!this.isMobi) {
                if (this.isAllMaskStyle3) {
                    isShow = false;
                }
            } else {
                // 手机视图下
                if (this.isHalfMaskStyle2 || this.isAllMaskStyle2 || this.isAllMaskStyle3) {
                    isShow = false;
                }
            }
            return isShow && this.showDesc && this.descText;
        },
        titleText() {
            return encodeHtmlStr(this.photoItem.desc, { notReplaceToNbsp: true });
        },
        descText() {
            return encodeHtmlStr(this.photoItem.desc2, { notReplaceToNbsp: true });
        },
        descClass() {
            const _class = {};
            if (this.descStyle === 1) {
                _class.photo_desc_style2 = true;
                if (this.isAllMaskStyle3) {
                    _class.photo_desc_all_mask = true;
                }
            } else {
                _class.photo_desc = true;
                if (this.isMobi) {
                    if (this.isHalfMask) {
                        _class.photo_desc_half_mask = true;
                    }
                }
            }
            return _class;
        },
    },
};
</script>

<style>
.module_list_photos_content .photo_desc_half_mask {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.photo_desc_all_mask {
    text-align: center;
}
</style>
